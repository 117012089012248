
var industry = '';
var urlGet = 'https://script.google.com/macros/s/AKfycbyl1XYSGnrnsTuXVwpth1710OYEb5n24Or1aGwBym-tAxLEvwJo/exec'

const buttonSubmit = document.getElementById('button_Submit');
buttonSubmit.addEventListener('click', onSubmit);

function onSubmit(){
    // console.log("YOu r Win Quots");

    var Name = document.getElementById('your-name').value;
    var phoneNo = document.getElementById('phone-no').value;
    var emailId = document.getElementById('mail-id').value;
    var eng = document.getElementById('select-industry');
    var industry = eng[eng.selectedIndex].text;
    var companyName = document.getElementById('company-name').value;
    var Requirments = document.getElementById('requirments').value;
    var getQuote = document.getElementById('getquote').value;
    var phoneno = /^\d{10}$/;
    // console.log("YOu r Win Quots industry :: ", industry);
    if (Name === "") {
        alert("Please enter Your Name !");
    } else if (phoneNo === "") {
        alert("Please enter 10 Digits(only) mobile number !");
    } else if (emailId === "") {
        alert("Please enter your valid Email-id !");
    } else if (industry === "" || industry === "Industry:") {
        alert("Please enter Your industry !");
    } else if (companyName === "") {
        alert("Please enter Your Company Name !");
    } else if (Requirments === "") {
        alert("Please enter Your Requirments !");
    } else {
        if (!phoneno.test(phoneNo)) {
            alert("Please enter 10 Digits(only) mobile number !");
        } else {
            document.getElementById("button_Submit").disabled = true;
            var obj = {
                name: Name,
                phoneno: phoneNo,
                emailid: emailId,
                Industry: industry,
                companyname: companyName,
                requirments: Requirments,
                inquiry: getQuote
            };
            // console.log("Fire obj :: ", obj);

            //   axios.post('https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendMail', obj, {
            axios.post(' https://us-central1-sendtoemail-3610d.cloudfunctions.net/quoteMail', obj, {
                headers: { // 'x-access-token': token
                }
            }).then(res => {
                // new Date().toLocaleString('en-IN', hour12 = true)
                //-------------Send Data on EXCEL----------------------
                const cdate = new Date().toLocaleString('en-IN', hour12 = true);

                var objExcel = {
                    Company: companyName,
                    Date: cdate,
                    Email: emailId,
                    Industry: industry,
                    Inquiry: getQuote,
                    Mobileno: phoneNo,
                    Name: Name,
                    Requirments: Requirments,

                };
                console.log("Excel data :: ", objExcel)
                var jqxhr = $.ajax({
                    url: urlGet,
                    method: "GET",
                    dataType: "json",
                    data: objExcel
                }).success(() => {
                    // do something
                    // alert("Done")
                    Name = document.getElementById('your-name').value = "";
                    phoneNo = document.getElementById('phone-no').value = "";
                    emailId = document.getElementById('mail-id').value = "";
                    companyName = document.getElementById('company-name').value = "";
                    Requirments = document.getElementById('requirments').value = "";
                    // alert("Form submitted successfully")
                    document.getElementById("button_Submit").disabled = false;
                    // alert("Done")
                    location.href = "../thanks.html";
                    // gtag_report_conversion('https://melzo.com/index.html');
                }


                );



            })
            //   window.open("https://melzo.com/dekho/5e7d9d1313fe800010b86c61", '_blank');
        }
    }
}